import React from "react"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"

const NotFoundPage = () => (
  <FadeIn>
    <SEO title="404: Not found" />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </FadeIn>
)

export default NotFoundPage
